import React from "react";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { makeTitle } from "utils/chartsUtils";
import { blue, greyTextColor } from "constants/colors";
import StyledLink from "../../reusables/StyledLink";
import colorByPageName from "../../../constants/colorByPageName";

const PADDING = 3;
const color = colorByPageName["Global map of Forest Cover for year 2020 in support to EUDR"];

const SidebarContent = () => {
  return (
    <>
      <Box padding={PADDING} color={color} sx={{ textAlign: "justify" }}>
        <br />
        Check the{" "}
        <StyledLink
          href="https://forobs.jrc.ec.europa.eu/GFC"
          target="_blank"
          color={color}
          aria-label="Link to Global forest cover 2020 - Data Access (external link will be opened in a new window)"
        >
          GFC website
        </StyledLink>{" "}
        for download and see the{" "}
        <StyledLink
          href="/Frequently_Asked_Questions_EUFO.docx"
          target="_blank"
          color={color}
          aria-label="Link to Frequently asked questions(link to word file to be downloaded)"
        >
          Frequently asked questions
        </StyledLink>
        .
        <br />
      </Box>
    </>
  );
};

export default SidebarContent;
