const colorByPageName = {
  // Accessibility
  "Accessibility statement": "#004929CC",
  // Forest
  "Global Forest Mapping and Monitoring": "#004924CC",
  "Tropical Moist Forest": "#006032",
  "Global Forest Attributes": "#007738",
  "Global Map of Forest Cover Changes and their Drivers": "#00893B",
  "Global map of Forest Cover for year 2020 in support to EUDR": "#00893B",
  "European Forest Attributes": "#007742",
  // Commodities
  "Production and trade of commodities": "#005C31CC",
  Production: "#6E9E75",
  Trade: "#6E9E75",
  "Global land use carbon fluxes": "#006E3ACC",
  "Carbon fluxes from land use 2000-2022": "#006E3ACC",

  // Commodities
  // Other
  "Reporting at country level": "#006032",
  "EU tools for forest monitoring": "#008142CC",
  //External
  "Landscape pattern analysis": "#008142",
  "Near-real-time analysis of time series of satellite data": "#008142",
  "Forest tree species distributions in Europe": "#008142",
  "IMPACT - Stand-alone toolbox for Image processing and environmental monitoring": "#008142",
  "'Biotrade' tool": "#008142",
  "Annual country fact sheets": "#006032",
  "Background information": "rgba(0, 73, 36, 0.8)",
  "Spatial reference data for forest disturbances in Europe": "#00000"
};

export type PageTitleType = keyof typeof colorByPageName;

export default colorByPageName;
