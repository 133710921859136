import Control, { ControlPositionType } from "./LeafletCustomControl";
import MapButton from "components/reusables/MapComponents/MapButton";
import { Box } from "@mui/material";

interface PropsI {
  position: ControlPositionType;
}

const MapAttributionButton = ({ position }: PropsI) => {
  return (
    <Control position={position}>
      <MapButton
        tooltip="Attribution"
        popupWrapperStyle={{ padding: 0 }}
        sx={{ backgroundColor: "#ccc" }}
        anchorposition={{ horizontal: "left", vertical: "top" }}
        popupContent={
          <Box className="attribution-wrapper">
            <a href="https://leafletjs.com" title="A JS library for interactive maps">
              Leaflet
            </a>
            |<a href="http://www.openstreetmap.org/copyright"> © OpenStreetMap</a> | © EuroGeographics for the
            administrative boundaries
          </Box>
        }
      >
        <svg
          viewBox="0 0 39 115"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          style={{ height: "100%", width: "100%" }}
        >
          <g>
            <path d="M26.102,91.63l3.372-13.589l7.465-30.088c1.33-5.36-1.938-10.783-7.298-12.113l-0.971-0.241   c-0.652-0.162-1.303-0.252-1.949-0.283l0.008-0.199c0,0-0.161,0.072-0.437,0.197c-3.072-0.011-7.561,2.97-7.561,2.97   c-8.72,6.079-20.654,14.141-18.886,17.981c2.678,5.816,15.748-9.487,12.618,3.131L9.091,72.984l-7.465,30.088   c-1.33,5.361,1.504,10.676,6.864,12.006l0.971,0.241c0.652,0.161,1.303,0.253,1.95,0.282l-0.009,0.199c0,0,0.162-0.071,0.438-0.197   c3.072,0.012,7.561-2.97,7.561-2.97c8.72-6.079,20.871-14.087,19.103-17.928C35.824,88.89,22.972,104.247,26.102,91.63z" />
            <circle cx="24.727" cy="15.217" r="14" />
          </g>
        </svg>
      </MapButton>
    </Control>
  );
};

export default MapAttributionButton;
