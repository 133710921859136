import { GhmCategoryType } from "../../../constants/layersCategories";
import { MapServerClient } from "utils/ogcClients";
import { GetMapParametersI, LayerConfigI, LayerDefinitionI } from "../../../types";
import {
  getLayersConfig,
  handleLayersName,
  makeCommonConfig,
  MakeConfigParamsI
} from "../../../utils/layerConfigUtils";

const mapFileName = "GFC2020";
const mapServerClient = new MapServerClient(mapFileName);

const layersDefinitions: LayerDefinitionI[] = [
  {
    type: "layer",
    name: "forest",
    layerName: "forest",
    title: "Global map of Forest Cover for year 2020 in support to EUDR",
    defaultSelected: true,
    description: ""
  }
];

const makeConfig = (props: MakeConfigParamsI): LayerConfigI => {
  const { definition, year } = props;
  const { name } = definition;

  const params: GetMapParametersI = {
    layers: handleLayersName(definition, year),
    ...mapServerClient.WMSParams
  };

  return {
    ...makeCommonConfig(props, mapServerClient),
    params,
    ghmCategory: "harmonized" as GhmCategoryType
  };
};

export const layersConfig = getLayersConfig(layersDefinitions, makeConfig);
